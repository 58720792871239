import React from 'react';

export const SnackPresentation = (props) => {
	return (
		<div className='snackPresentationDiv' >
			<h4 className='snackPresentationHeader'>{props.snackSize}</h4>
			<img className='snackPresentationImg' alt='snack' src={props.img} />
		</div>
	);
};

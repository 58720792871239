import React from 'react';

export const HealthRegister = (props) => {
	return (
		<section
		className='healthRegisterSection'
			>
			<div className='' style={{ width: '80%' }}>
				<div className='healthRegisterFirstLeftSection' >
					<div>
						<h3 style={{fontSize:'2.2rem'}}>Health Register</h3>
					</div>
					<div>
						<p style={{fontSize:'1.2rem'}}>NSA-001970-2016</p>
					</div>
					<div>
						<h3 style={{fontSize:'2.2rem'}}>Ingredients</h3>
					</div>
					<div>
						{props.ingredientsList.map((item, index) => {
							return (
								<div key={index} className='ingredientsCheckList'>
									<i className='far fa-check-circle greenCheck'></i>
									<p >{item}</p>
								</div>
							);
						})}
					</div>
					<div>
						<h3>Net Weight 60g (2.1 oz)</h3>
					</div>
				</div>
				<div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							borderBottom: '2px dotted #000000',
						}}>
						<div
							style={{
								fontSize: '10vh',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
							}}>
							<p style={{ paddingBottom: '0', color:'red' }}>NO</p>
						</div>
						<div className='noAddedIngredients' >
							<h4>Added Preservatives</h4>
							<h4>Artificial Colors</h4>
							<h4>Artificial Flavors</h4>
						</div>
					</div>
					<div>
						<div className='noContainsList'>
							<i
								className='fa-solid fa-star'
								style={{
									borderColor: props.iconColor,
									color: props.iconColor,
								}}></i>
							<h2>Zero Trans Fats Included</h2>
						</div>
						<div className='noContainsList'>
							<i
								className='fa-solid fa-star'
								style={{
									borderColor: props.iconColor,
									color: props.iconColor,
								}}></i>
							<h2>Good Source of Calcium</h2>
						</div>
						<div className='noContainsList'>
							<i
								className='fa-solid fa-star'
								style={{
									borderColor: props.iconColor,
									color: props.iconColor,
								}}></i>
							<h2>Does not contain Gluten</h2>
						</div>
						<div className='noContainsList'>
							<i
								className='fa-solid fa-star'
								style={{
									borderColor: props.iconColor,
									color: props.iconColor,
								}}></i>
							<h2>No Added Sugars Included</h2>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

import React from 'react';
import LasOriginalesLogo from '../Images/lasOriginalesLogo.png';
import LaurelLeafImage from '../Images/laurelLeafImage.png';
import PeopleNotCropped from '../Images/peopleThumbsUpNotCropped.jpg';
import GuyWithFoodRack from '../Images/DownloadedFromSite/guyWithFoodRack.jpg';
import AchirasBackground from '../Images/DownloadedFromSite/ACHIRAS-DEL-HUILA-ACH2.png';
import HundredBaked from '../Images/baked100Image.png';
import styled from 'styled-components';
import { NavBar } from '../Components/NavBar';
import { Footer } from '../Components/Footer';
import { WavesPeopleAchira } from '../Components/WavesPeopleAchira';
import { Paragraph } from '../Components/StyledComponents';

const Us = () => {
	const Button = (props) => {
		return (
			<button  className='contactButton'>
				<a href={`${props.link}`} style={{color:'white',textDecoration:'none'}}>
				<i className='fa-solid fa-envelopes-bulk iconSpacing'></i>
				Contact Us
				</a>
			</button>
		);
	};

	return (
		<div className='WoodenBackground'>
			<NavBar></NavBar>
			<WavesPeopleAchira pageTitle='Us'></WavesPeopleAchira>

			<section>
				<div className='usFirstSectionDiv'>
					<div className='col'>
						<SubHeader>Business</SubHeader>
						<div>
							<Paragraph alignment='justify'>
								Ermita SAS food producer is a company
								traditionally recognized for its Achiras del
								Huila products, which began its commercial and
								production activity in the city of Neiva Huila
								since 1986, today thanks to its expansion at the
								national level, it is strategically located in
								the km 49 via Bogotá Fusagasugá.
							</Paragraph>
						</div>
					</div>
					<div
						className='col'
						style={{ display: 'flex', justifyContent: 'center' }}>
						<img
							src={LasOriginalesLogo}
							alt='achirasdelhuila logo'
						/>
					</div>
				</div>
			</section>
			<section>
				<div style={{ backgroundColor: 'white' }}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							backgroundImage: `url(${AchirasBackground})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: '113% 100%',
							height: '100%',
							paddingBottom: '1.5vh',
						}}>
						<video width='65%' loop controls={true}>
							<source
								src='https://productoraermita.com/wp-content/uploads/2020/09/Achiras-del-huila-video.mp4'
								type='video/mp4'
							/>
							Your browser does not support the video tag. I
							suggest you upgrade your browser.
						</video>
					</div>
				</div>
			</section>
			<section className='usOrangeCompanySection'>
				<div>
					<div>
						<p style={{ fontSize: '2.2rem', fontWeight: '500' }}>
							Do you want to be a part of our company?
						</p>
					</div>
					<div >
						<Button link='/Contact' >Contact Us</Button>
					</div>
				</div>
			</section>
			<section className='usSlideShowSection'>
				<div>
					<div
						className=' usSlideShowSection'
						style={{
							backgroundColor: 'white',
							paddingTop: '10vh',
						}}>
						<div  className="usSlideShow">
							<div
								style={{
									backgroundColor: 'rgb(248,246,243)',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-around',
									borderRadius: '1vw',
								}}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										justifyContent: 'center',
										width: '90%',
									}}>
									<div
										id='carouselExampleIndicators'
										className='carousel slide carouselSlideShow'
										data-bs-ride='carousel'>
										<div className='carousel-indicators'>
											<button
												type='button'
												data-bs-target='#carouselExampleIndicators'
												data-bs-slide-to='0'
												className='active'
												aria-current='true'
												aria-label='Slide 1'></button>
											<button
												type='button'
												data-bs-target='#carouselExampleIndicators'
												data-bs-slide-to='1'
												aria-label='Slide 2'></button>
										</div>
										<div className='carousel-inner '>
											<div className='carousel-item active'>
												<img
													src={PeopleNotCropped}
													className='d-block w-100 carouselImage'
													alt='...'
												/>
											</div>
											<div className='carousel-item'>
												<img
													src={GuyWithFoodRack}
													className='d-block w-100 carouselImage'
													alt='...'
												/>
											</div>
										</div>
										<button
											className='carousel-control-prev'
											type='button'
											data-bs-target='#carouselExampleIndicators'
											data-bs-slide='prev'>
											<span
												className='carousel-control-prev-icon'
												aria-hidden='true'></span>
											<span className='visually-hidden'>
												Previous
											</span>
										</button>
										<button
											className='carousel-control-next'
											type='button'
											data-bs-target='#carouselExampleIndicators'
											data-bs-slide='next'>
											<span
												className='carousel-control-next-icon'
												aria-hidden='true'></span>
											<span className='visually-hidden'>
												Next
											</span>
										</button>
									</div>

									<SubHeader>Mission</SubHeader>

									<Paragraph
										style={{
											marginBottom: '6vh',
											marginTop: '2vh',
										}}
										alignment='center'>
										We are a family business with more than
										30 years of experience. We seek to
										manufacture typical products of the
										Huila region of excellent quality and to
										the taste of the consumer in Colombia
										and other countries of America.
									</Paragraph>
									<div style={{ marginBottom: '6vh' }}>
										<span className='fa fa-star checked'></span>
										<span className='fa fa-star checked'></span>
										<span className='fa fa-star checked'></span>
										<span className='fa fa-star checked'></span>
										<span className='fa fa-star checked'></span>
									</div>
								</div>
							</div>
						</div>
						<div
						className='colombianProductDiv'
							style={{
								
								
								paddingBottom: '2vh',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
							}}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}>
								<img
									style={{ width: '18%' }}
									src={HundredBaked}
									alt='baked emblem'></img>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										marginLeft: '1vw',
									}}>
									<h2>Colombian Product</h2>
									<Paragraph>100% Baked</Paragraph>
								</div>
							</div>
							<SubHeader className='extraTopPaddingOnTopTwoPointFive'>Vision</SubHeader>
							<Paragraph alignment='justify'>
								To be the number 1 producer of Achiras biscuit in
								Colombia, with high quality standards. We seek
								to expand in the international market of the
								snack line.
							</Paragraph>
							<SubHeader className='extraTopPaddingOnTopTwoPointFive'>
								We are the Creators of the Colombian flavor
							</SubHeader>
							<img
								style={{ width: '18%' }}
								src={LaurelLeafImage}
								alt='Laurel Leaf'
								className='extraTopPaddingOnTopTwoPointFive'
							/>
							<div className='extraTopPaddingOnTopTwoPointFive' style={{ display: 'flex' }}>
								<div style={{ width: '40%' }}>
									<ul style={{paddingRight:'4vw'}}>
										<li className='noListItemDot'>
											<i className='far fa-check-circle greenCheck'></i>
											<Paragraph>Quality</Paragraph>
										</li>
										<li className='noListItemDot'>
											<i className='far fa-check-circle greenCheck'></i>
											<Paragraph>Commitment</Paragraph>
										</li>
										<li className='noListItemDot'>
											<i className='far fa-check-circle greenCheck'></i>
											<Paragraph>Efficiency</Paragraph>
										</li>
										<li className='noListItemDot'>
											<i className='far fa-check-circle greenCheck'></i>
											<Paragraph>Culture</Paragraph>
										</li>
										<li className='noListItemDot'>
											<i className='far fa-check-circle greenCheck'></i>
											<Paragraph>Natural</Paragraph>
										</li>
									</ul>
								</div>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
									}}>
									<ul>
										<li className='noListItemDot'>
											<i className='far fa-check-circle greenCheck'></i>
											<Paragraph>Taste</Paragraph>
										</li>
										<li className='noListItemDot'>
											<i className='far fa-check-circle greenCheck'></i>
											<Paragraph>Production</Paragraph>
										</li>
										<li className='noListItemDot'>
											<i className='far fa-check-circle greenCheck'></i>
											<Paragraph>Job</Paragraph>
										</li>
									</ul>
								</div>
							</div>
							<Button >Contact Us</Button>
						</div>
					</div>
				</div>
			</section>

			<Footer></Footer>
		</div>
	);
};

export default Us;

const SubHeader = styled.h1``;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


const PageLink = (props) => {
	const currentPage =
		props.linkName === window.location.pathname.slice(1) ? true : false;

		const linkDict = {
			Products: {
				'Achiras Del Huila': 'AchirasDelHuila',
				Almojabanas: 'Almojabanas',
				Crunchy: 'Crunchy',
			},
			Customers: { 'My Account': 'MyAccount' },
		};
	
	const [links, setLinks] = useState([]);

	useEffect(() => {
		const tempArray = [];
		if(props.dropDownLink === true){
			
			//console.log(linkDict[props.page])
			for(var item in Object.keys(linkDict[props.page])){
				
				tempArray.push(Object.keys(linkDict[props.page])[item])
			}
			setLinks(tempArray)
		}
		
		
		return () => {};
	},[]);

	

	return props.dropDownLink === true ? (
		<div className='btn-group'>
			<button
				style={{
					color: currentPage === true ? 'green' : 'black',
					fontWeight: '400',
					textAlign: 'center',
					fontSize: '1.4em',
					marginBottom: '0',
					padding: '15px',
					backgroundColor: 'transparent',
					border: 'none',
				
					
				}}
				className='btn btn-secondary dropdown-toggle customDropDown'
				type='button'
				id='dropdownMenuButton'
				data-bs-toggle='dropdown'
				aria-expanded='true'>
				{props.page}
			</button>
			<ul className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
				{links.map((item, index) => {
					return (
						<li key={index}>
							<a className='dropdown-item' href={`/${linkDict[props.page][item]}`}>
								{item}
							</a>
						</li>
					);
				})}
			</ul>
		</div>
	) : (
		<Link style={{ textDecoration: 'none' }} to={props.page}>
			<p
				style={{
					color: currentPage === true ? 'green' : 'black',
					fontWeight: '400',
					textAlign: 'center',
					fontSize: '1.4em',
					marginBottom: '0',
					padding: '15px',
				}}>
				{props.linkName}
			</p>
		</Link>
	);
};

export default PageLink;

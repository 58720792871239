import './App.css';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Us from './Pages/Us';
import { Start } from './Pages/Start';
import { Products } from './Pages/Products';
import { Contact } from './Pages/Contact';
import { Customers } from './Pages/Customers';
import { PrivacyPolicy } from './Pages/PrivacyPolicy';
import { Email } from './Pages/Email';
import { AchirasDelHuila } from './Pages/AchirasDelHuila';
import { Almojabanas } from './Pages/Almojabanas';
import { Crunchy } from './Pages/Crunchy';
import { MyAccount } from './Pages/MyAccount';

function App() {
	return (
		<Router>
			<Switch>
				<Route path='/Start' component={Start} exact></Route>
				<Route path='/Us' component={Us} exact></Route>
				<Route path='/Products' component={AchirasDelHuila} exact></Route>
				<Route path='/Contact' component={Contact} exact></Route>
				<Route path='/Customers' component={Contact} exact></Route>
				<Route
					path='/PrivacyPolicy'
					component={Contact}
					exact></Route>
				<Route path='/Email' component={Contact} exact></Route>
				<Route
					path='/AchirasDelHuila'
					component={AchirasDelHuila}
					exact></Route>
				<Route
					path='/Almojabanas'
					component={Almojabanas}
					exact></Route>
				<Route path='/Crunchy' component={Crunchy} exact></Route>
				<Route path='/MyAccount' component={MyAccount} exact></Route>
				<Route component={Start}></Route>
			</Switch>
		</Router>
	);
}

export default App;

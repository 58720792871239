import React from 'react';
import { Footer } from '../Components/Footer';
import { HealthRegister } from '../Components/HealthRegister';
import { NavBar } from '../Components/NavBar';
import { ProductHeader } from '../Components/ProductHeader';
import { SnackPresentation } from '../Components/snackPresentation';
import AchirasChips from '../Images/DownloadedFromSite/ACHIRAS-ORIGINALS-CHIPS-HEADER.png';
import FiftyGramsSixUnits from '../Images/DownloadedFromSite/6-X-50GR-EAN-7709213273456.png';
import TwentyFiveGramsSixUnits from '../Images/DownloadedFromSite/12-X-25-GR-EAN-7709369903993.png'
import TwoHundredFiftyGrams from '../Images/DownloadedFromSite/250GR-EAN-7709369903948-1.png'

export const AchirasDelHuila = () => {
	const ingredientsList = [
		'Achira Starch',
		'Fresh Curd',
		'Fresh Eggs',
		'Margarine',
		'Salt',
		'Corn',
	];
	return (
		<div>
			<NavBar></NavBar>
			<ProductHeader
				bannerType='productBannerYellow'
				fadeColor='#F8B107'
				backgroundPic={AchirasChips}
				title='Achiras del Huila'
				subTitle='The Originals'
				headerText='Typical Colombian snack from the department of Huila, mixed and baked by hand, its main ingredients are achiras starch, curd, egg yolks, butter and salt.'
				textColor='white'
				headerTextColor='#b50114'
				titleTextColor='#006115'></ProductHeader>

			<HealthRegister
				iconColor='#e6b828'
				ingredientsList={ingredientsList}></HealthRegister>

			<section className='snackPresentationSection'>
				<SnackPresentation
					snackSize='25 Grams x 12 Units'
					img={TwentyFiveGramsSixUnits}></SnackPresentation>

				<SnackPresentation
					snackSize='50 Grams x 6 Units'
					img={FiftyGramsSixUnits}></SnackPresentation>
				<SnackPresentation
					snackSize='120 Grams'
					img={AchirasChips}></SnackPresentation>
				<SnackPresentation
					snackSize='250 Grams'
					img={TwoHundredFiftyGrams}></SnackPresentation>
			</section>
			<Footer></Footer>
		</div>
	);
};

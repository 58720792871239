import React from 'react';
import { Footer } from '../Components/Footer';
import { NavBar } from '../Components/NavBar';
import { WavesPeopleAchira } from '../Components/WavesPeopleAchira';

import { FrequentQuestions } from '../Components/FrequentQuestions';

export const Contact = () => {
	
	const iconStyle = {
		color: 'rgb(26,170,88)',
		fontSize: '1.5em',
		marginBottom: '1vh',
		marginTop: '1vh',
	};

	return (
		<div className='WoodenBackground'>
			<NavBar></NavBar>
			<WavesPeopleAchira pageTitle='Contact'></WavesPeopleAchira>
			<section
				style={{
					display: 'flex',
					backgroundColor: 'white',
					flexDirection: 'row',
					justifyContent: 'center',
					paddingTop: '4vh',
				}}>
				<div
					className='contactThreeSectionDiv'>
					<div className='contactInfoBox'>
					<i className="fa-brands fa-whatsapp-square" style={iconStyle}></i>
					
						<a href='https://wa.me/19176509540'>
							<p>917-650-9540</p>
						</a>
					</div>
					<div className='contactInfoBox'>
						<i className='fa-solid fa-envelope' style={iconStyle}></i>
						<a href='mailto:contact@uschirasdelhuila.com'>
							<p>contact@uschirasdelhuila</p>
						</a>
					</div>
					<div className='contactInfoBox'>
						<i
							className='fa-solid fa-location-dot'
							style={iconStyle}></i>
						<a href='https://www.google.com/maps/place/5715+Bent+Pine+Dr,+Orlando,+FL+32822/@28.4764577,-81.311385,17z/data=!3m1!4b1!4m5!3m4!1s0x88e7636f87b5306b:0x60dd94cad01d707b!8m2!3d28.4764577!4d-81.309191'>
							<p>5715 Bent Pine Dr, Orlando, FL 32822</p>
						</a>
					</div>
				</div>
			</section>
			
			<Footer></Footer>
		</div>
	);
};

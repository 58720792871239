import React from 'react';
import PageLink from './PageLink';
import flagLogo from '../Images/flagLogo.png';

export const NavBar = () => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					borderBottom: 'solid',
					borderColor: '#00000038',
					borderWidth: '1px',
					backgroundColor: 'white',
					paddingRight: '7vw',
					paddingLeft: '7vw',
				}}>
				<div>
					<img
					className='navbarFlag'
						style={{  padding: '10px' }}
						src={flagLogo}
						alt='The logo with the flag'
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						margin: '10px',
						alignItems: 'center',
					}}>
					<div className='btn-group navbarHamburgerMenu'>
						<button
							type='button'
							className='btn btn-secondary dropdown-toggle '
							data-bs-toggle='dropdown'
							aria-expanded='false'>
							
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='26'
								height='26'
								fill='currentColor'
								className='bi bi-list'
								viewBox='0 0 16 16'>
								<path
									fill-rule='evenodd'
									d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'
								/>
							</svg>
						</button>
						<ul className='dropdown-menu dropdown-menu-end'>
							<li>
								<a className='dropdown-item' href='Start'>
									Start
								</a>
							</li>
							<li>
								<a className='dropdown-item' href='Us'>
									Us
								</a>
							</li>
							<li>
								<a className='dropdown-item' href='AchirasDelHuila'>
									Achiras Del Huila
								</a>
							</li>
							<li>
								<a className='dropdown-item' href='Almojabanas'>
									Almojábanas
								</a>
							</li>
							<li>
								<a className='dropdown-item' href='Crunchy'>
									Crunchy
								</a>
							</li>
							<li>
								<a className='dropdown-item' href='Contact'>
									Contact
								</a>
							</li>
						</ul>
					</div>

					<div className='navbarLink'>
						<PageLink page='Start' linkName='Start' color='black' />
						<PageLink page='Us' linkName='Us' color='black' />
						<PageLink
							page='Products'
							linkName='Products'
							color='black'
							dropDownLink={true}></PageLink>
						<PageLink
							page='Contact'
							linkName='Contact'
							color='black'
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

import styled from "styled-components";

export const Paragraph = styled.p`
	font-weight: 400;
	text-align: ${(props) =>
		props.alignment === 'center'
			? 'center'
			: props.alignment === 'justify'
			? 'justify'
			: 'left'};
	font-size: 1.3em;
	margin-bottom: 0;
	z-index: 4;
`;


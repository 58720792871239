import React from 'react';
import { Footer } from '../Components/Footer';
import { NavBar } from '../Components/NavBar';
import ColombianFlagBanner from '../Images/DownloadedFromSite/Recurso-2-3x.png';
import ColombiaLogo from '../Images/DownloadedFromSite/logo-pais.png';
import achirasSlideLogo from '../Images/DownloadedFromSite/ADH-SLIDER-001.png';
import achirasChips from '../Images/DownloadedFromSite/ADH-SLIDER-002.png';
import spicyChips from '../Images/DownloadedFromSite/ADH-SLIDER-003.png';
import almojabanasChips from '../Images/DownloadedFromSite/ADH-SLIDER-004.png';
import threePackChips from '../Images/DownloadedFromSite/ADH-SLIDER-005.png';
import WoodBackground from '../Images/DownloadedFromSite/ADH-FONDO.jpg'


export const Start = () => {
	const UsButton = () => {
		return (
			<a href='/Us'>
				<button
					style={{
						backgroundColor: '#1aaa58',
						width: '14%',
						borderRadius: '40px',
						padding: '2%',
						color: 'white',
						border: 'none',
						fontSize: '1.2rem',
					}}>
					<i
						className='fa-solid fa-chevron-right'
						style={{ marginRight: '8%' }}></i>
					Us
				</button>
			</a>
		);
	};

	return (
		<div className='WoodenBackground'>
			<NavBar></NavBar>
			<section className='startPageSlideShowSection'>
				<div className='startPageSlideShowAndText'>
					<div
						style={{
							width: '50%',
							height: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'start',
						}}>
						<div
							id='startPageSlides'
							className='carousel slide'
							data-bs-ride='carousel'>
							<div className='carousel-inner'>
								<div className='carousel-item active'>
									<img
										src={achirasSlideLogo}
										className='d-block w-100'
										alt='...'
									/>
								</div>
								<div className='carousel-item'>
									<img
										src={achirasChips}
										className='d-block w-100'
										alt='Slideshow'
									/>
								</div>
								<div className='carousel-item'>
									<img
										src={spicyChips}
										className='d-block w-100'
										alt='Slideshow'
									/>
								</div>
								<div className='carousel-item'>
									<img
										src={almojabanasChips}
										className='d-block w-100'
										alt='Slideshow'
									/>
								</div>
								<div className='carousel-item'>
									<img
										src={threePackChips}
										className='d-block w-100'
										alt='Slideshow'
									/>
								</div>
							</div>
						</div>
					</div>
					<div
					className='startPageSlideShowAndTextSecondDiv'
						>
						<h5
							className='merriweatherFont'
							style={{ fontSize: '2rem' }}>
							Welcome to
						</h5>
						<h1 className='merriweatherFont startPageHeaderOne'>
							Food Producer Ermita SAS
						</h1>
						<p
							className='startPageSlideShowAndTextParagraph'
							>
							Productora De Alimentos Ermita SAS is a company
							traditionally recognized for its achiras products
							from Huila, which began its commercial and
							production activity in the city of Neiva Huila since
							1986, today thanks to its expansion at the national
							level, it is strategically located in the km 49 via
							Bogotá – Fusagasugá.
						</p>
						<UsButton></UsButton>
					</div>
				</div>
			</section>
			<div
				className='startPageMiniBanner'
				style={{
					backgroundImage: `url(${ColombianFlagBanner})`,
					justifyContent: 'center',

					color: 'white',
				}}>
				<img src={ColombiaLogo} alt='Colombian Logo'></img>
				<h4 style={{ width: '60vw', marginLeft: '5vw' }}>
					Live the tradition and share the emotion with our producs
					fills us with Colombian pride, today we feel satisfied to be
					a country brand.
				</h4>
			</div>
			<section className='chipsCardSection'
			style={{backgroundImage: `url('${WoodBackground}')`,
			backgroundSize:'100vw'}}
			>
				<div className='chipsCardContainerDiv'>
					<div className='chipsCardOuterDiv' style={{}}>
						<div className='chipsCardInnerDiv'>
							<h4
								className='merriweatherFont'
								style={{ fontSize: '2.2rem' }}>
								Achiras
							</h4>
							<p style={{ fontSize: '1.3rem' }}>Las Originales</p>
							<a href={`/Achiras`}>
								<button
									style={{
										backgroundColor: '#1aaa58',
										width: '44%',
										borderRadius: '40px',
										padding: '4%',
										color: 'white',
										border: 'none',
										fontSize: '1.2rem',
									}}>
									See product
									<i
										style={{ marginLeft: '10%' }}
										className='fa-solid fa-arrow-right'></i>
								</button>
							</a>
							<img
								className='chipsCardImage'
								alt='chips one'
								src={achirasChips}></img>
						</div>
					</div>
					<div className='chipsCardOuterDiv'>
						<div className='chipsCardInnerDiv'>
							<h4
								className='merriweatherFont'
								style={{ fontSize: '2.2rem' }}>
								Crunchy - Spicy Achiras
							</h4>
							<p style={{ fontSize: '1.3rem' }}>Cheese Sticks</p>
							<a href={`/Almojabanas`}>
								<button
									style={{
										backgroundColor: '#1aaa58',
										width: '44%',
										borderRadius: '40px',
										padding: '4%',
										color: 'white',
										border: 'none',
										fontSize: '1.2rem',
									}}>
									See product
									<i
										style={{ marginLeft: '10%' }}
										className='fa-solid fa-arrow-right'></i>
								</button>
							</a>
							<img
								className='chipsCardImage'
								alt='chips one'
								src={spicyChips}></img>
						</div>
					</div>
					<div className='chipsCardOuterDiv'>
						<div className='chipsCardInnerDiv'>
							<h4
								className='merriweatherFont'
								style={{ fontSize: '2.2rem' }}>
								Almojábanas
							</h4>
							<p style={{ fontSize: '1.3rem' }}>Crispy</p>
							<a href={`/Almojabanas`}>
								<button
									style={{
										backgroundColor: '#1aaa58',
										width: '44%',
										borderRadius: '40px',
										padding: '4%',
										color: 'white',
										border: 'none',
										fontSize: '1.2rem',
									}}>
									See product
									<i
										style={{ marginLeft: '10%' }}
										className='fa-solid fa-arrow-right'></i>
								</button>
							</a>
							<img
								className='chipsCardImage'
								alt='chips one'
								src={almojabanasChips}></img>
						</div>
					</div>
				</div>
			</section>

			<div className='startPageColombianFlagDiv'
				style={{
					position: 'relative',
					
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				<svg
					style={{
						zIndex: '1',
						position: 'absolute',
					}}
					xmlns='http://www.w3.org/2000/svg'
					version='1.1'
					width='100vw'
					height='42vh'
					viewBox='0 0 1000 200'>
					<g mask='url("#SvgjsMask1215")' fill='none'>
						<path
							d='M 0,18 C 22.4,15 67.2,2.4 112,3 C 156.8,3.6 179.2,20 224,21 C 268.8,22 291.2,8.8 336,8 C 380.8,7.2 403.2,16.4 448,17 C 492.8,17.6 515.2,10.8 560,11 C 604.8,11.2 627.2,19.6 672,18 C 716.8,16.4 739.2,3.4 784,3 C 828.8,2.6 852.8,14.6 896,16 C 939.2,17.4 979.2,11.2 1000,10L1000 200L0 200z'
							fill='rgba(255, 255, 255, 1)'></path>
						<path
							d='M 0,26 C 66.8,28.4 200.4,36.4 334,38 C 467.6,39.6 534.8,32.4 668,34 C 801.2,35.6 933.6,43.6 1000,46L1000 200L0 200z'
							fill='rgba(255, 255, 255, 1)'></path>
						<path
							d='M 0,62 C 22.4,59.6 67.2,48.8 112,50 C 156.8,51.2 179.2,68.8 224,68 C 268.8,67.2 291.2,46.4 336,46 C 380.8,45.6 403.2,64.6 448,66 C 492.8,67.4 515.2,53.2 560,53 C 604.8,52.8 627.2,65.6 672,65 C 716.8,64.4 739.2,51 784,50 C 828.8,49 852.8,59 896,60 C 939.2,61 979.2,56 1000,55L1000 200L0 200z'
							fill='rgba(255, 214, 76, 1)'></path>
						<path
							d='M 0,75 C 22.4,76.2 67.2,82 112,81 C 156.8,80 179.2,69.2 224,70 C 268.8,70.8 291.2,84.4 336,85 C 380.8,85.6 403.2,73.6 448,73 C 492.8,72.4 515.2,81.2 560,82 C 604.8,82.8 627.2,76.2 672,77 C 716.8,77.8 739.2,86.6 784,86 C 828.8,85.4 852.8,74.4 896,74 C 939.2,73.6 979.2,82 1000,84L1000 200L0 200z'
							fill='rgba(255, 214, 76, 1)'></path>
						<path
							d='M 0,95 C 22.4,96.8 67.2,104.8 112,104 C 156.8,103.2 179.2,89.8 224,91 C 268.8,92.2 291.2,108.8 336,110 C 380.8,111.2 403.2,97.4 448,97 C 492.8,96.6 515.2,108.8 560,108 C 604.8,107.2 627.2,92.2 672,93 C 716.8,93.8 739.2,111.6 784,112 C 828.8,112.4 852.8,95.6 896,95 C 939.2,94.4 979.2,106.2 1000,109L1000 200L0 200z'
							fill='rgba(22, 80, 183, 1)'></path>
						<path
							d='M 0,134 C 40,130.2 120,116.6 200,115 C 280,113.4 320,125.8 400,126 C 480,126.2 520,116 600,116 C 680,116 720,124.8 800,126 C 880,127.2 960,122.8 1000,122L1000 200L0 200z'
							fill='rgba(22, 80, 183, 1)'></path>
						<path
							d='M 0,144 C 20,145.2 60,150.8 100,150 C 140,149.2 160,140.6 200,140 C 240,139.4 260,146.4 300,147 C 340,147.6 360,141.4 400,143 C 440,144.6 460,156.4 500,155 C 540,153.6 560,136.2 600,136 C 640,135.8 660,152.8 700,154 C 740,155.2 760,143.4 800,142 C 840,140.6 860,146.8 900,147 C 940,147.2 980,143.8 1000,143L1000 200L0 200z'
							fill='rgba(178, 35, 24, 1)'></path>
						<path
							d='M 0,163 C 33.4,164.4 100.2,169.4 167,170 C 233.8,170.6 267.2,166.2 334,166 C 400.8,165.8 434.2,171 501,169 C 567.8,167 601.2,154.8 668,156 C 734.8,157.2 768.6,173.6 835,175 C 901.4,176.4 967,165.4 1000,163L1000 200L0 200z'
							fill='rgba(178, 35, 24, 1)'></path>
						<path
							d='M 0,196 C 66.8,193.2 200.4,183.2 334,182 C 467.6,180.8 534.8,190.2 668,190 C 801.2,189.8 933.6,182.8 1000,181L1000 200L0 200z'
							fill='rgba(255, 255, 255, 1)'></path>
					</g>
				</svg>
				<div
					className='startPageMiniBanner'
					style={{
						justifyContent: 'space-between',

						width: '100vw',

						postion: 'absolute',
						zIndex: '2',
					}}>
					<h4 className='startPageSecondBannerText'>
						Do you want to know more about our products?
					</h4>
					<a href='/Us'>
						<button
							style={{
								backgroundColor: '#1aaa58',
								width: '7vw',
								borderRadius: '40px',
								padding: '2%',
								paddingTop: '1vh',
								color: 'white',
								border: 'none',
								fontSize: '1.2rem',
							}}>
							<i
								className='fa-solid fa-chevron-right'
								style={{ marginRight: '8%' }}></i>
							Us
						</button>
					</a>
				</div>
			</div>
			<section
				style={{
					width: '100vw',
					backgroundColor: 'white',
					display: 'flex',
					zIndex: '3',
					paddingTop: '3vh',
				}}>
				
			</section>

			<Footer></Footer>
		</div>
	);
};

import React from 'react';
import { Footer } from '../Components/Footer';
import { NavBar } from '../Components/NavBar';

export const MyAccount = () => {
	return (
		<div>
			<NavBar></NavBar>
			<Footer></Footer>
		</div>
	);
};

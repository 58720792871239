import React from 'react';
import { Footer } from '../Components/Footer';
import { HealthRegister } from '../Components/HealthRegister';
import { NavBar } from '../Components/NavBar';
import { ProductHeader } from '../Components/ProductHeader';
import { SnackPresentation } from '../Components/snackPresentation';
import CrunchyChips from '../Images/DownloadedFromSite/ACHIRAS-SPICY-CHIPS-HEADER.png';
import SixtyGramsSixUnits from '../Images/DownloadedFromSite/6-X-60GR-EAN-7709213273487-1024x636.png'

export const Crunchy = () => {
	const ingredientsList = [
		'Sago Starch',
		'Fresh Curd',
		'Fresh Eggs',
		'Margarine',
		'Salt',
		'Spices',
	];
	return (
		<div>
			<NavBar></NavBar>
			<ProductHeader
				bannerType='productBannerRed'
				fadeColor='#006115'
				backgroundPic={CrunchyChips}
				title='Crunchy Spicy Achiras'
				subTitle='Sagu Crackers'
				headerText='It is a product with Colombian gastronomic tradition with a crispy texture and delicious flavor that contains a spicy touch, which you can accompany with any drink.'
				textColor='white'
				headerTextColor='#401d0b'
				titleTextColor='#a33636'></ProductHeader>
			<HealthRegister
				iconColor='#a31211'
				ingredientsList={ingredientsList}></HealthRegister>
			<section className='snackPresentationSection'>
				<SnackPresentation
					snackSize='60 Grams x 6 Units'
					img={SixtyGramsSixUnits}></SnackPresentation>
			</section>
			<Footer></Footer>
		</div>
	);
};

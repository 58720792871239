import React from 'react';
import { Footer } from '../Components/Footer';
import { HealthRegister } from '../Components/HealthRegister';
import { NavBar } from '../Components/NavBar';
import { ProductHeader } from '../Components/ProductHeader';
import { SnackPresentation } from '../Components/snackPresentation';
import AlmojabanasChips from '../Images/DownloadedFromSite/ACHIRAS-ALMOJABANAS-CHIPS-HEADER.png';
import FortyGramsSixUnits from '../Images/DownloadedFromSite/6-X-40GR-EAN-7709213273449-1024x639.png'

export const Almojabanas = () => {
	const ingredientsList = ['Cassava Starch', 'Fresh Curd',"Sago Starch", "Fresh Eggs", "Margarine", "Salt", "Corn"]
	return (
		<div>
			<NavBar></NavBar>
			<ProductHeader
				textColor="white"
				headerTextColor="#b50114"
				titleTextColor="#481f23"
				bannerType="productBannerBrown"
				fadeColor='#D66F06'
				backgroundPic={AlmojabanasChips}
				title='Almojábanas'
				subTitle='Crispy'
				headerText='The crispy almojábana is a snack that is carefully produced with high quality natural ingredients. It is an innovative product in the industry.'></ProductHeader>
			<HealthRegister iconColor="#804b3e" ingredientsList={ingredientsList}></HealthRegister>
			<section className='snackPresentationSection'>
				<SnackPresentation
					snackSize='40 Grams x 6 Units'
					img={FortyGramsSixUnits}></SnackPresentation>

				<SnackPresentation
					snackSize='100 Grams'
					img={AlmojabanasChips}></SnackPresentation>
				</section>
			<Footer></Footer>
		</div>
	);
};
